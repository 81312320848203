import React from 'react';

import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardMedia,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    useMediaQuery,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import {
    merge as lodashMerge
} from 'lodash';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({


    contentOrganisationChartRoot: style => ({
        ...style.contentOrganisationChartRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentOrganisationChartRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentOrganisationChartRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentOrganisationChartRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentOrganisationChartRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentOrganisationChartRoot.xl,
        },
    }),

    contentOrganisationChartTitle: style => ({
        ...style.contentOrganisationChartTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentOrganisationChartTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentOrganisationChartTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentOrganisationChartTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentOrganisationChartTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentOrganisationChartTitle.xl,
        },
    }),

    contentOrganisationChartBoxSections: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        [theme.breakpoints.up('md')]: {
            gap: '5rem',
        },
    },
    contentOrganisationChartBoxSection: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentOrganisationChartBoxSectionLabel: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '0px',
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(4),
        },
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.light,
        paddingLeft: theme.spacing(4),
    },
    contentOrganisationChartBoxCards: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '1rem',
    },
    contentOrganisationChartCard: {
        display: 'flex',
        flexDirection: 'column',
        width: '15rem',
    },
    contentOrganisationChartCardMedia: {
        objectFit: 'cover',
        maxHeight: '15rem',
        objectPosition: 'top center',
    },
    contentOrganisationChartCardName: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
    },
    contentOrganisationChartCardGrade: {
        fontSize: '1rem',
        fontStyle: 'italic',
        marginBottom: theme.spacing(2),
    },
    contentOrganisationChartListItem: {
        gap: theme.spacing(2),
    },
    contentOrganisationChartAvatar: {
        width: '20vw',
        height: '30vw',
    }
}));





//
// Composant permettant d'afficher un trombinoscope
//
export const ContentOrganisationChart = (props) => {

    const { title, params, style, data } = props;


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        lodashMerge(
            {
                contentOrganisationChartRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentOrganisationChartTitle: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Actuellement, deux modes d'affichage sont disponbiles :
    // > md : mode 'desktop' (grand écran)
    // <= md : mode 'mobile'
    const isMDUp = useMediaQuery(theme => theme.breakpoints.up('md'));


    return (
        <Box
            className={classes.contentOrganisationChartRoot}
        >
            {title &&
                <Typography
                    className={classes.contentOrganisationChartTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            <Box
                className={classes.contentOrganisationChartBoxSections}
            >
                {data.map((section, index_section) =>
                    <Box
                        key={index_section}
                        className={classes.contentOrganisationChartBoxSection}
                    >
                        <Typography
                            className={classes.contentOrganisationChartBoxSectionLabel}
                            component="h2"
                            variant="h6"
                        >
                            {section.section_label}
                        </Typography>


                        {isMDUp ?

                            <Box
                                className={classes.contentOrganisationChartBoxCards}
                            >
                                {section.lst_persons.map((person, index_person) =>
                                    <Card
                                        key={index_person}
                                        className={classes.contentOrganisationChartCard}
                                    >
                                        <CardMedia
                                            className={classes.contentOrganisationChartCardMedia}
                                            component="img"
                                            src={person.photo_resized_src}
                                            srcSet={person.photo_resized_srcset}
                                            sizes="30vw" // TODO : à calculer dynamiquement
                                            title={person.photo_alt}
                                            alt={person.photo_alt}
                                        />
                                        <CardContent>
                                            <Typography
                                                className={classes.contentOrganisationChartCardName}
                                                variant="body2"
                                                color="textPrimary"
                                                component="p"
                                            >
                                                {person.firstname} {person.lastname}
                                            </Typography>
                                            {person.grade &&
                                                <Typography
                                                    className={classes.contentOrganisationChartCardGrade}
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component="p"
                                                >
                                                    {person.grade}
                                                </Typography>
                                            }
                                            {person.activity && <Typography variant="body2" color="textSecondary" component="p">{person.activity}</Typography>}
                                            {person.contact && <Typography variant="body2" color="textSecondary" component="p">{person.contact}</Typography>}
                                            {person.extra_infos && <Typography variant="body2" color="textSecondary" component="p">{person.extra_infos}</Typography>}
                                        </CardContent>
                                    </Card>
                                )}
                            </Box>

                        :

                            <List>
                                {section.lst_persons.map((person, index_person) =>
                                    <ListItem
                                        key={index_person}
                                        className={classes.contentOrganisationChartListItem}
                                        alignItems="flex-start"
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                className={classes.contentOrganisationChartAvatar}
                                                variant="rounded"
                                                src={person.photo_resized_src}
                                                srcSet={person.photo_resized_srcset}
                                                sizes="30vw" // TODO : à calculer dynamiquement
                                                title={person.photo_alt}
                                                alt={person.photo_alt}
                                            />
                                        </ListItemAvatar>

                                        <ListItemText
                                            primary={
                                                <>
                                                    <Typography
                                                        className={classes.contentOrganisationChartCardName}
                                                        variant="body2"
                                                        color="textPrimary"
                                                        component="p"
                                                    >
                                                        {person.firstname} {person.lastname}
                                                    </Typography>
                                                    {person.grade &&
                                                        <Typography
                                                            className={classes.contentOrganisationChartCardGrade}
                                                            variant="body2"
                                                            color="textSecondary"
                                                            component="p"
                                                        >
                                                            {person.grade}
                                                        </Typography>
                                                    }
                                                    {person.activity && <Typography variant="body2" color="textSecondary" component="p">{person.activity}</Typography>}
                                                    {person.contact && <Typography variant="body2" color="textSecondary" component="p">{person.contact}</Typography>}
                                                    {person.extra_infos && <Typography variant="body2" color="textSecondary" component="p">{person.extra_infos}</Typography>}
                                                </>
                                            }
                                        />
                                    </ListItem>
                                )}
                            </List>

                        }
                    </Box>
                )}
            </Box>
        </Box>
    );
}
